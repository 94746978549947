@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Gantari:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* ONBOARDING: Homeowner view */
  /* Toggle */
  .switchWithPending {
    position: relative;
    display: inline-block;
    width: 351px;
    height: 54px;
  }

  .toggleWithPendingInput:checked + .slider:before {
    transform: translateX(174px);
  }

/* Valuation chart CSS */
#tooltip-circle {
  position: relative;
}

#tooltip-text {
  position: absolute;
  background-color: #5D5C5D;
  border-radius: 10px;
  padding: 0.8rem 1rem;
  color: white;
  font-weight: 700;
  display: none;
  z-index: 10;
}

/* MAPBOX ADDRESS AUTOFILL STYLES */
mapbox-address-autofill {
  display: flex;
  flex: auto;
}

mapbox-search-listbox > div > div {
  positon: relative !important;
  z-index: 10000 !important;
}

/* DASHBOARD TRI-STATE-TOGGLE */
.tri-state-toggle-radio {
  height: 30px;
  width: 30px;
  appearance: none;
  background-color: black;
  border-radius: 50%;
  opacity: 1;
}
.tri-state-toggle-radio:hover {
  cursor: pointer;
}
.tri-state-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  height: max-content;
  border-radius: 50px;
}
#offers-toggle {
  opacity: 1;
}

/* DASHBOARD CAROUSEL */
.dashboardCarouselLeftOverlay,
.dashboardCarouselRightOverlay {
  background-color: #FFFCF9;
  box-shadow:
    inset 20px 33px 50px 0 #FFFCF9,
    inset 0 66px 15px 0px #FBF8F5,
    inset 0 99px 5px 0px #FBF7F4;
}